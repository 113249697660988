import React, { useState } from "react";
import PropTypes from "prop-types";
import { Col, Form, FormLabel, Row } from "react-bootstrap";
import DatePicker from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";

const DATEPICKER_FORMAT_DATE = "dd/MM/yyyy";
const FORMAT_DATE_MOMENT = "MM/DD/YYYY";
const FORMAT_DATE_MOMENT_HOURS = "MM/DD/YYYY HH:mm";
const FORMAT_DATE = "YYYY/DD/MM";

const FormDatePicker = ({
  label,
  nameInput,
  onResponse,
  value,
  columnsSize,
  required,
  showTimeSelect,
  format,
  maxDate,
  disabled,
}) => {
  const date = value ? new Date(value) : null;

  const [dateChange, setDateChange] = useState(date);

  const changeValue = (date) => {
    if (!date) {
      setDateChange(null);

      return onResponse(null, nameInput);
    }

    let format = FORMAT_DATE_MOMENT;

    if (showTimeSelect) {
      format = FORMAT_DATE_MOMENT_HOURS;
    }

    date = new Date(moment(date, FORMAT_DATE).format(format));

    setDateChange(date);

    onResponse(date, nameInput);
  };

  return (
    <Form.Group className="form-group">
      <Row>
        <Col sm="12" lg={columnsSize}>
          <FormLabel>{label}</FormLabel>
        </Col>
        <Col sm="12" lg={columnsSize}>
          <DatePicker
            className="form-control"
            selected={dateChange}
            onChange={(date) => changeValue(date)}
            dateFormat={format}
            isClearable={true}
            closeOnScroll={true}
            placeholderText={format}
            required={required}
            showTimeSelect={showTimeSelect}
            timeFormat="HH:mm"
            timeIntervals={10}
            timeCaption="Time"
            maxDate={maxDate ? maxDate : null}
            disabled={disabled}
          />
        </Col>
      </Row>
    </Form.Group>
  );
};

FormDatePicker.propTypes = {
  label: PropTypes.string.isRequired,
  nameInput: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  onResponse: PropTypes.func.isRequired,
  columnsSize: PropTypes.any,
  required: PropTypes.bool,
  showTimeSelect: PropTypes.bool,
  format: PropTypes.string,
  minDate: PropTypes.any,
  maxDate: PropTypes.any,
  disabled: PropTypes.bool,
};

FormDatePicker.defaultProps = {
  columnsSize: "6",
  required: false,
  showTimeSelect: false,
  format: DATEPICKER_FORMAT_DATE,
  maxDate: null,
  disabled: false,
};

export default FormDatePicker;
