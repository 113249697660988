import React from "react";
import { Col, Row } from "react-bootstrap";

const DividerDashed = () => {
  return (
    <Row>
      <Col xs={12} lg={12}>
        <hr className="dashed"></hr>
      </Col>
    </Row>
  );
};

export default DividerDashed;
