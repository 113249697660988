export const REACT_APP_NOVITAERP_API_USER_AUTHENTICATE =
  "/api/v1/user/authenticate";
export const REACT_APP_NOVITAERP_API_PURCHASE_ORDER_LIST =
  "/api/v1/purchase-order";
export const REACT_APP_NOVITAERP_API_SALES_LIST = "/api/v1/sales";
export const REACT_APP_NOVITAERP_API_SALES_GET_ALL_IDS =
  "/api/v1/sales/get-all-ids";
export const REACT_APP_NOVITAERP_API_SALES_GET_BY_IDS =
  "/api/v1/sales/get-by-ids";
export const REACT_APP_NOVITAERP_API_SALES_DETAIL = "/api/v1/sales/{id}";
export const REACT_APP_NOVITAERP_API_SALES_EDIT = "/api/v1/sales/{id}";
export const REACT_APP_NOVITAERP_API_SALES_EDIT_STATUS_WORKFLOW =
  "/api/v1/sales/edit-status/{id}";
export const REACT_APP_NOVITAERP_API_SALES_CREATE = "/api/v1/sales";
export const REACT_APP_NOVITAERP_API_SALES_EXPORT = "/api/v1/sales/export/";
export const REACT_APP_NOVITAERP_API_SALES_EXPORT_SALES =
  "/api/v1/sales/export-sales/";
export const REACT_APP_NOVITAERP_API_SALES_SEARCH_MERGE =
  "/api/v1/sales/search/merge";
export const REACT_APP_NOVITAERP_API_SALES_EXPORT_WORKFLOW_COST =
  "/api/v1/workflow-activity/export-cost";
export const REACT_APP_NOVITAERP_API_SALES_EXPORT_WORKFLOW_COST_ADVANCED =
  "/api/v1/workflow-activity/export-cost/advanced-filter";
export const REACT_APP_NOVITAERP_API_SALES_EXPORT_SALE_ADVANCED =
  "/api/v1/workflow-activity/sales/export-sales";
export const REACT_APP_NOVITAERP_API_SALES_IMPORT_NEW_FIELDS =
  "/api/v1/sales/import-new-fields";
export const REACT_APP_NOVITAERP_API_SALE_COMMENT_LIST = "/api/v1/sale-comment";
export const REACT_APP_NOVITAERP_API_SALE_COMMENT_NEW = "/api/v1/sale-comment";
export const REACT_APP_NOVITAERP_API_SALE_EDITION_REQUIREMENT_LIST =
  "/api/v1/sale-edition-requirement";
export const REACT_APP_NOVITAERP_API_SALE_EDITION_REQUIREMENT_DETAIL =
  "/api/v1/sale-edition-requirement/{id}";
export const REACT_APP_NOVITAERP_API_SALE_EDITION_REQUIREMENT_EDIT =
  "/api/v1/sale-edition-requirement/{id}";
export const REACT_APP_NOVITAERP_API_SALE_EDITION_REQUIREMENT_CREATE =
  "/api/v1/sale-edition-requirement";
export const REACT_APP_NOVITAERP_API_POST_SALE_EDITION_REQUIREMENT_DETAIL =
  "/api/v1/post-sale-edition-requirement/{id}";
export const REACT_APP_NOVITAERP_API_POST_SALE_EDITION_REQUIREMENT_EDIT =
  "/api/v1/post-sale-edition-requirement/{id}";
export const REACT_APP_NOVITAERP_API_POST_SALE_EDITION_REQUIREMENT_CREATE =
  "/api/v1/post-sale-edition-requirement";
export const REACT_APP_NOVITAERP_API_SALE_TASK_LIST = "/api/v1/sale-task";
export const REACT_APP_NOVITAERP_API_SALE_TASK_DETAIL =
  "/api/v1/sale-task/{id}";
export const REACT_APP_NOVITAERP_API_SALE_TASK_CREATE = "/api/v1/sale-task";
export const REACT_APP_NOVITAERP_API_SALES_LOG_LIST = "/api/v1/sales-log";
export const REACT_APP_NOVITAERP_API_SALES_LOG_WORKFLOW_LIST =
  "/api/v1/workflow-sale/logs";
export const REACT_APP_NOVITAERP_API_SALES_LOG_DETAIL =
  "/api/v1/sales-log/{id}";
export const REACT_APP_NOVITAERP_API_SALES_LOG_NEW = "/api/v1/sales-log";
export const REACT_APP_NOVITAERP_API_POST_SALE_SERVICES_LIST =
  "/api/v1/post-sale-service";
export const REACT_APP_NOVITAERP_API_POST_SALE_SERVICES_DETAIL =
  "/api/v1/post-sale-service/{id}";
export const REACT_APP_NOVITAERP_API_POST_SALE_SERVICES_EDIT =
  "/api/v1/post-sale-service/{id}";
export const REACT_APP_NOVITAERP_API_POST_SALE_SERVICES_CREATE =
  "/api/v1/post-sale-service";
export const REACT_APP_NOVITAERP_API_POST_SALE_LOG_LIST =
  "/api/v1/post-sale-service-log";
export const REACT_APP_NOVITAERP_API_POST_SALE_LOG_DETAIL =
  "/api/v1/post-sale-service-log/{id}";
export const REACT_APP_NOVITAERP_API_POST_SALE_LOG_NEW =
  "/api/v1/post-sale-service-log";
export const REACT_APP_NOVITAERP_API_POST_SALE_COMMENT_LIST =
  "/api/v1/post-sale-service-comment";
export const REACT_APP_NOVITAERP_API_POST_SALE_COMMENT_NEW =
  "/api/v1/post-sale-service-comment";
export const REACT_APP_NOVITAERP_API_POST_SALE_EXPORT =
  "/api/v1/post-sale-service/export";
export const REACT_APP_NOVITAERP_API_ACCOUNTING_LIST = "/api/v1/accounting";
export const REACT_APP_NOVITAERP_API_ACCOUNTING_GET_BY_IDS =
  "/api/v1/accounting/get-by-ids";
export const REACT_APP_NOVITAERP_API_ACCOUNTING_DETAIL =
  "/api/v1/accounting/{id}";
export const REACT_APP_NOVITAERP_API_ACCOUNTING_EDIT =
  "/api/v1/accounting/{id}";
export const REACT_APP_NOVITAERP_API_ACCOUNTING_CREATE = "/api/v1/accounting";
export const REACT_APP_NOVITAERP_API_ACCOUNTING_LOG_LIST =
  "/api/v1/accounting-log";
export const REACT_APP_NOVITAERP_API_ACCOUNTING_LOG_DETAIL =
  "/api/v1/accounting-log/{id}";
export const REACT_APP_NOVITAERP_API_ACCOUNTING_LOG_NEW =
  "/api/v1/accounting-log";
export const REACT_APP_NOVITAERP_API_ACCOUNTING_EXPORT_WORKFLOW_COST =
  "/api/v1/accounting/export-workflow-cost";
export const REACT_APP_NOVITAERP_API_ACCOUNTING_EXPORT_SALES =
  "/api/v1/accounting/export-sales";
export const REACT_APP_NOVITAERP_API_ACCOUNTING_EXPORT_SALE_COMMISSIONS =
  "/api/v1/accounting/export-sale-commissions";
export const REACT_APP_NOVITAERP_API_ACCOUNTING_EXPORT_SALE_COMMISSIONS_REVIEWS =
  "/api/v1/accounting/export-sale-commissions/reviews";
export const REACT_APP_NOVITAERP_API_ACCOUNTING_SALE_COMMISSIONS_UPDATE =
  "/api/v1/accounting/update/sale-commissions";
export const REACT_APP_NOVITAERP_API_EMPLOYEE_LIST = "/api/v1/employee";
export const REACT_APP_NOVITAERP_API_EMPLOYEE_GET_OWN_USER =
  "/api/v1/employee/get-own-user";
export const REACT_APP_NOVITAERP_API_EMPLOYEE_DETAIL = "/api/v1/employee/{id}";
export const REACT_APP_NOVITAERP_API_EMPLOYEE_TASK_LIST =
  "/api/v1/employee-task";
export const REACT_APP_NOVITAERP_API_EMPLOYEE_TASK_LIST_OWN =
  "/api/v1/employee-task/list-own";
export const REACT_APP_NOVITAERP_API_EMPLOYEE_TASK_COUNT_OWN =
  "/api/v1/employee-task/count-own";
export const REACT_APP_NOVITAERP_API_EMPLOYEE_TASK_DETAIL =
  "/api/v1/employee-task/{id}";
export const REACT_APP_NOVITAERP_API_EMPLOYEE_TASK_EDIT =
  "/api/v1/employee-task/{id}";
export const REACT_APP_NOVITAERP_API_EMPLOYEE_TASK_CREATE =
  "/api/v1/employee-task";
export const REACT_APP_NOVITAERP_API_SALES_EMPLOYEE_TASK_LIST =
  "/api/v1/sales-employee-task";
export const REACT_APP_NOVITAERP_API_SALES_EMPLOYEE_TASK_DETAIL =
  "/api/v1/sales-employee-task/{id}";
export const REACT_APP_NOVITAERP_API_SALES_EMPLOYEE_TASK_EDIT =
  "/api/v1/sales-employee-task/{id}";
export const REACT_APP_NOVITAERP_API_SALES_EMPLOYEE_TASK_CREATE =
  "/api/v1/sales-employee-task";
export const REACT_APP_NOVITAERP_API_MANUFACTURING_TASK_LIST =
  "/api/v1/manufacturing-task";
export const REACT_APP_NOVITAERP_API_MANUFACTURING_TASK_DETAIL =
  "/api/v1/manufacturing-task/{id}";
export const REACT_APP_NOVITAERP_API_CUSTOMISED_LIST_LIST =
  "/api/v1/customised-list";
export const REACT_APP_NOVITAERP_API_CUSTOMISED_LIST_DETAIL =
  "/api/v1/customised-list/detail";
export const REACT_APP_NOVITAERP_API_CUSTOMISED_LIST_UPDATE =
  "/api/v1/customised-list/update";
export const REACT_APP_NOVITAERP_API_CUSTOMISED_LIST_CREATE =
  "/api/v1/customised-list";
export const REACT_APP_NOVITAERP_API_CUSTOMISED_LIST_DELETE =
  "/api/v1/customised-list/delete";
export const REACT_APP_NOVITAERP_API_DIAMOND_PRODUCT_LIST =
  "/api/v1/diamond-product";
export const REACT_APP_NOVITAERP_API_DIAMOND_PRODUCT_GD_SEARCH =
  "/api/v1/diamond-product/gd-search";
export const REACT_APP_NOVITAERP_API_PRODUCT_LIST = "/api/v1/product";
export const REACT_APP_NOVITAERP_API_WORKFLOW_SALE_CREATE =
  "/api/v1/workflow-sale";
export const REACT_APP_NOVITAERP_API_WORKFLOW_SALE_LIST =
  "/api/v1/workflow-sale";
export const REACT_APP_NOVITAERP_API_WORKFLOW_SALE_WORKFLOW_ACTIVITY =
  "/api/v1/workflow-sale/sale/{saleID}";
export const REACT_APP_NOVITAERP_API_WORKFLOW_SALE =
  "/api/v1/workflow-sale/sale";
export const REACT_APP_NOVITAERP_API_WORKFLOW_SALE_EDIT =
  "/api/v1/workflow-sale/{id}";
export const REACT_APP_NOVITAERP_API_WORKFLOW_ACTIVITY_CREATE =
  "/api/v1/workflow-activity";
export const REACT_APP_NOVITAERP_API_WORKFLOW_ACTIVITY_EDIT =
  "/api/v1/workflow-activity/{id}";
export const REACT_APP_NOVITAERP_API_WORKFLOW_ACTIVITY_SEARCH_AUTOCOMPLETE =
  "/api/v1/workflow-activity/search-atutocomplete";
export const REACT_APP_NOVITAERP_API_WORKFLOW_ACTIVITY_ADVANCED_FILTER =
  "/api/v1/workflow-activity/advanced-filter";
export const REACT_APP_NOVITAERP_API_WORKFLOW_ACTIVITY_SAVE_COSTS =
  "/api/v1/workflow-activity/save-costs";
export const REACT_APP_NOVITAERP_API_WORKFLOW_ACTIVITY_SEARCH_STOCK_NUMBER =
  "/api/v1/workflow-activity/search-stock-number";
export const REACT_APP_NOVITAERP_API_WORKFLOW_ACTIVITY_LOAD_INVOICE =
  "/api/v1/workflow-activity/load-invoice";
export const REACT_APP_NOVITAERP_API_WORKFLOW_FAVOURITE_FILTER =
  "/api/v1/workflow-activity/favourite-filter";
export const REACT_APP_NOVITAERP_API_WORKFLOW_FAVOURITE_FILTER_LIST =
  "/api/v1/workflow-activity/favourite-filter/list";
export const REACT_APP_NOVITAERP_API_JEWELLER_LIST = "/api/v1/jeweller";
export const REACT_APP_NOVITAERP_API_PRODUCT_ENGAGEMENT_RING =
  "/api/v1/product/engagement/ring";
export const REACT_APP_NOVITAERP_API_PRODUCT_ENGAGEMENT_RING_AVALIABLE_CATALOG =
  "/api/v1/product/engagement/ring/avaliable/catalog";
export const REACT_APP_NOVITAERP_API_PRODUCT_ENGAGEMENT_RING_CLAW_METAL =
  "/api/v1/product/engagement/ring/claw/metal";
export const REACT_APP_NOVITAERP_API_SALE_COMMISSIONS =
  "/api/v1/sales/commissions";
export const REACT_APP_NOVITAERP_API_EMPLOYEE_COMMISSION_RATE =
  "/api/v1/employee/commission/rate";
export const REACT_APP_NOVITAERP_API_SALE_EMPLOYEE_COMMISSION_DETAIL =
  "/api/v1/sales/commissions/employee/detail";
export const REACT_APP_NOVITAERP_API_WORKFLOW_RETUNR_SALE_ORIGIN =
  "/api/v1/workflow-activity/workflow/return/sale/origin/";

export const REACT_APP_NOVITA_DOCS_DOMAIN =
  process.env.REACT_APP_NOVITA_DOCS_DOMAIN;
export const REACT_APP_NOVITA_DOCS_RINGS =
  process.env.REACT_APP_NOVITA_DOCS_RINGS;
export const REACT_APP_NOVITA_DOCS_EARRINGS =
  process.env.REACT_APP_NOVITA_DOCS_EARRINGS;
export const REACT_APP_NOVITA_DOCS_BRACELETS =
  process.env.REACT_APP_NOVITA_DOCS_BRACELETS;
export const REACT_APP_NOVITA_DOCS_NECKLACES =
  process.env.REACT_APP_NOVITA_DOCS_NECKLACES;
export const REACT_APP_NOVITA_DOCS_ENG_RINGS =
  process.env.REACT_APP_NOVITA_DOCS_ENG_RINGS;
export const REACT_APP_NOVITA_DOCS_CABLE_CHAINS =
  process.env.REACT_APP_NOVITA_DOCS_CABLE_CHAINS;

export const REACT_APP_NOVITA_WEBSITE_DOMAIN =
  process.env.REACT_APP_NOVITA_WEBSITE_DOMAIN;
export const REACT_APP_NOVITA_WEBSITE_WEDDING_BANDS =
  process.env.REACT_APP_NOVITA_WEBSITE_WEDDING_BANDS;
export const REACT_APP_NOVITA_WEBSITE_ENGAGEMENT_RINGS =
  process.env.REACT_APP_NOVITA_WEBSITE_ENGAGEMENT_RINGS;
export const REACT_APP_NOVITA_WEBSITE_JEWELLERY =
  process.env.REACT_APP_NOVITA_WEBSITE_JEWELLERY;
export const REACT_APP_NOVITA_WEBSITE_LOOSE_DIAMOND =
  process.env.REACT_APP_NOVITA_WEBSITE_LOOSE_DIAMOND;
export const REACT_APP_NOVITA_WEBSITE_DIAMOND_DETAIL =
  process.env.REACT_APP_NOVITA_WEBSITE_DIAMOND_DETAIL;
export const REACT_APP_NOVITA_WEBSITE_ENG_RING_DETAIL =
  process.env.REACT_APP_NOVITA_WEBSITE_ENG_RING_DETAIL;
export const REACT_APP_NOVITA_WEBSITE_RING_DETAIL =
  process.env.REACT_APP_NOVITA_WEBSITE_RING_DETAIL;
export const REACT_APP_NOVITA_WEBSITE_JEWELLERY_DETAIL =
  process.env.REACT_APP_NOVITA_WEBSITE_JEWELLERY_DETAIL;

export const REACT_APP_PRODUCT_CATEGORY_ID_RING = 1;
export const REACT_APP_PRODUCT_CATEGORY_ID_EARRING = 2;
export const REACT_APP_PRODUCT_CATEGORY_ID_BRACELET = 3;
export const REACT_APP_PRODUCT_CATEGORY_ID_NECKLACE = 4;
export const REACT_APP_PRODUCT_CATEGORY_ID_ER = 5;
export const REACT_APP_PRODUCT_CATEGORY_ID_DIAMOND = 6;
export const REACT_APP_PRODUCT_CATEGORY_ID_JEWELLERY = 7;
export const REACT_APP_PRODUCT_CATEGORY_ID_CABLE_CHAIN = 8;
export const REACT_APP_TAX_PRICE_GST_PERCENT = 10;

export const REACT_APP_SALE_TYPE_LOOSE_DIAMOND = "Loose Diamond";
export const REACT_APP_SALE_TYPE_WEDDING_BANDS = "Wedding Band";
export const REACT_APP_SALE_TYPE_ENGAGEMENT_RINGS = "Engagement Ring";
export const REACT_APP_SALE_TYPE_JEWELLERY = "Jewellery";
export const REACT_APP_SALE_TYPE_JEWELLERY_CATEGORY_RING = "Ring";

export const REACT_APP_NOVITAERP_API_SALES_MULTIMEDIA_CREATE =
  "/api/v1/sales-multimedia";
export const REACT_APP_NOVITAERP_API_SALES_MULTIMEDIA_UPDATE =
  "/api/v1/sales-multimedia/{id}";
export const REACT_APP_NOVITAERP_API_SALES_MULTIMEDIA_LIST =
  "/api/v1/sales-multimedia";
export const REACT_APP_NOVITAERP_API_SALES_MULTIMEDIA_DELETE =
  "/api/v1/sales-multimedia/{id}";
export const REACT_APP_NOVITAERP_API_SALES_MULTIMEDIA_UPDATE_FILE =
  "/api/v1/sales-multimedia/upload-file";

export const FOLDER_POST_SALE_SERVICE = "post_sale_service";
export const FOLDER_SALE = "sales";

export const AWS_S3_ACCESS_POINT_URL =
  process.env.REACT_APP_AWS_S3_ACCESS_POINT_URL;
export const AWS_REGION = process.env.REACT_APP_AWS_REGION;
export const AWS_ACCESS_KEY_ID = process.env.REACT_APP_AWS_ACCESS_KEY_ID;
export const AWS_S3_ENDPOINT = process.env.REACT_APP_AWS_S3_ENDPOINT;
export const AWS_S3_ACCESS_POINT_URL_POST_SALE_SERVICE =
  process.env.REACT_APP_AWS_S3_ACCESS_POINT_URL_POST_SALE_SERVICE;
export const AWS_S3_ACCESS_POINT_URL_CAD_FILES =
  process.env.REACT_APP_AWS_S3_ACCESS_POINT_URL_CAD_FILES;

export const REACT_APP_NOVITAERP_API_POST_SALE_SERVICE_MULTIMEDIA_CREATE =
  "/api/v1/post-sale-service-multimedia";
export const REACT_APP_NOVITAERP_API_POST_SALE_SERVICE_MULTIMEDIA_UPDATE =
  "/api/v1/post-sale-service-multimedia/{id}";
export const REACT_APP_NOVITAERP_API_POST_SALE_SERVICE_MULTIMEDIA_LIST =
  "/api/v1/post-sale-service-multimedia";
export const REACT_APP_NOVITAERP_API_POST_SALE_SERVICE_MULTIMEDIA_DELETE =
  "/api/v1/post-sale-service-multimedia/{id}";
export const REACT_APP_REFERENCE_AG_WBJ = "AG-WBJ";

export const REACT_APP_WORKFLOW_TYPE_MANUFACTURING = "Manufacturing";
export const REACT_APP_WORKFLOW_TYPE_DELIVERING_TO_CUSTOMER =
  "Delivering to customer";
export const REACT_APP_WORKFLOW_TYPE_VALUATION = "Valuation";
export const REACT_APP_WORKFLOW_TYPE_APPRAISAL = "Appraisal";
export const REACT_APP_WORKFLOW_SALE_STATUS_PROGRESS = "In progress";
export const REACT_APP_WORKFLOW_SALE_STATUS_TRANSIT = "In transit";
export const REACT_APP_WORKFLOW_SALE_STATUS_COLLECTED_COMPLETED =
  "Collected/Completed";
export const REACT_APP_WORKFLOW_SALE_STATUS_CANCELLED = "Cancelled";

export const SALE_COMMISSION_TYPE_SALES = "sales";
export const SALE_COMMISSION_TYPE_REVIEWS = "reviews";

export const EVENT_TYPE = "invitee.canceled";

export const CURRENCY_SYMBOLS = [
  "$",
  "€",
  "£",
  "¥",
  "₣",
  "₹",
  "HK",
  "₩",
  "RM",
  "D",
];

export const LIST_COUNTRIES = [
  "Afghanistan",
  "Aland Islands",
  "Albania",
  "Algeria",
  "American Samoa",
  "Andorra",
  "Angola",
  "Anguilla",
  "Antigua and Barbuda",
  "Argentina",
  "Armenia",
  "Aruba",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bermuda",
  "Bhutan",
  "Bolivia",
  "Bosnia and Herzegovina",
  "Botswana",
  "Brazil",
  "British Virgin Islands",
  "Brunei Darussalam",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Cape Verde",
  "Cayman Islands",
  "Central African Republic",
  "Chad",
  "Chile",
  "China",
  "Colombia",
  "Comoros",
  "Congo",
  "Cook Islands",
  "Costa Rica",
  "Cote d'Ivoire",
  "Croatia",
  "Cuba",
  "Cyprus",
  "Czech Republic",
  "Democratic Republic of the Congo",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Ethiopia",
  "Faeroe Islands",
  "Falkland Islands",
  "Fiji",
  "Finland",
  "France",
  "French Guiana",
  "French Polynesia",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Gibraltar",
  "Greece",
  "Greenland",
  "Grenada",
  "Guadeloupe",
  "Guam",
  "Guatemala",
  "Guernsey",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Holy See",
  "Honduras",
  "Hong Kong",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran",
  "Iraq",
  "Ireland",
  "Isle of Man",
  "Israel",
  "Italy",
  "Jamaica",
  "Japan",
  "Jersey",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Kuwait",
  "Kyrgyzstan",
  "Laos",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libyan Arab Jamahiriya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Macao",
  "Macedonia",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands",
  "Martinique",
  "Mauritania",
  "Mauritius",
  "Mayotte",
  "Mexico",
  "Micronesia",
  "Moldova",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Montserrat",
  "Morocco",
  "Mozambique",
  "Myanmar",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands",
  "Netherlands Antilles",
  "New Caledonia",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "Niue",
  "Norfolk Island",
  "North Korea",
  "Northern Mariana Islands",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Palestine",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Pitcairn",
  "Poland",
  "Portugal",
  "Puerto Rico",
  "Qatar",
  "Reunion",
  "Romania",
  "Russian Federation",
  "Rwanda",
  "Saint Helena",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Pierre and Miquelon",
  "Saint Vincent and the Grenadines",
  "Saint-Barthelemy",
  "Saint-Martin",
  "Samoa",
  "San Marino",
  "Sao Tome and Principe",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Korea",
  "South Sudan",
  "Spain",
  "Sri Lanka",
  "Sudan",
  "Suriname",
  "Svalbard and Jan Mayen Islands",
  "Swaziland",
  "Sweden",
  "Switzerland",
  "Syrian Arab Republic",
  "Tajikistan",
  "Tanzania",
  "Thailand",
  "Timor-Leste",
  "Togo",
  "Tokelau",
  "Tonga",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Turks and Caicos Islands",
  "Tuvalu",
  "U.S. Virgin Islands",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United Kingdom",
  "United States",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Venezuela",
  "Viet Nam",
  "Wallis and Futuna Islands",
  "Western Sahara",
  "Yemen",
  "Zambia",
  "Zimbabwe",
];

export const FIELD_SHIP_CUSTOMER_ADRRESS = "shipCustomersAddress";
export const FIELD_PICK_UP_AT_SHOWROOM = "pickUpAtShowroom";
export const FIELD_COUNTRY = "shippingCountry";

export const BTN_DUPLICATE_SALE = "duplicateSale";
export const BTN_EDIT_SALE = "editSale";
export const BTN_CREATE_WORKFLOW = "createWorkflow";
export const BTN_EDIT_WORKFLOW_ACTIVITY = "editWorkflowActivity";
export const BTN_QUALITY_CONTROL_CHECK_START = "qualityControlStart";
export const BTN_QUALITY_CONTROL_CHECK_PENDING = "qualityControlPending";
export const BTN_QUALITY_CONTROL_CHECK_APPROVED = "qualityControlApproved";
export const BTN_QUALITY_CONTROL_CHECK_REJECTED = "qualityControlRejected";

export const ACTION_CREATE = "CREATE";

export const LIST_ENGRAVING_FONT_NO_WEDDING = [
  "Times New Roman",
  "Trebuchet MS",
  "Helvetica Italic",
  "Georgia Italic",
  "Love Letter",
];

export const LIST_ENGRAVING_FONT_WEDDING = [
  "Love Letter",
  "Script",
  "Adorable",
  "Roman",
  "Helvetica",
  "Desdemona",
];

export const LIST_TYPES_SETTINGS = [
  "Accent",
  "Band width",
  "Center Stone",
  "Setting height",
  "Sidestones",
  "Shank’s Profile",
  "Other",
];

export const LIST_SPECIAL_REQUEST = [
  {
    value: "Setting Center Stone",
    label: "Setting Center Stone",
    disabled: false,
  },
  {
    value: "Setting Side Stones",
    label: "Setting Side Stones",
    disabled: false,
  },
  {
    value: "Setting Center and Side Stones",
    label: "Setting Center and Side Stones",
    disabled: false,
  },
  { value: "CAD Required", label: "CAD Required", disabled: false },
];

export const TOTAL_PERSONAL_TABS = 20;

export const ALLOWED_FILE_TYPES = [
  "image/jpeg",
  "image/png",
  "application/pdf",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "video/mp4",
  "audio/mp3",
];

export const EXCHANGE_CREATE = "Create";
export const EXCHANGE_EDIT = "Edit";

export const STATUS_REPORT_IN_PROCES = "In process";
export const STATUS_REPORT_COMPLETED = "Completed";
export const STATUS_REPORT_FAILED = "Failed";
