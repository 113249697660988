import {
  Accordion,
  Button,
  Card,
  Col,
  ListGroup,
  Row,
  Table,
} from "react-bootstrap";
import {
  dateFormatCustom,
  formatCustomerCreationDate,
  formatDate,
} from "../../../../utils/dateUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { AddFollowUps } from "./add-followup";
import AddAppointmentsForm from "./add-appointment-form";
import { Link } from "react-router-dom";
import Auth from "../../../../Auth";
import CustomerSalesRepresentatives from "../sales-reps";
import {
  existCancelAppointmetCalendly,
  getCancelAppointmetCalendly,
} from "./actions";
import moment from "moment";

export default function AppointmentInformation({ customer }) {
  const [showAddAppointment, setshowAddAppointment] = useState(false);
  const [showAddFollowup, setshowAddFollowup] = useState(false);

  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const [selectedFollowup, setselectedFollowup] = useState(null);

  const handleEditAppointment = (appointment, event) => {
    event.stopPropagation();
    setSelectedAppointment(appointment);
    setshowAddAppointment(true);
  };

  const handleEditFollowUp = (followUp, appointment, event) => {
    event.stopPropagation();
    setSelectedAppointment(appointment);
    setselectedFollowup(followUp);
    setshowAddFollowup(true);
  };

  const handleAddFollowup = (appointment, event) => {
    event.stopPropagation();
    setSelectedAppointment(appointment);
    setshowAddFollowup(true);
  };

  const accessFollowUp = Auth.canAccessRolesAndLeader("rolesAccessCRMCreate");

  const accesEdit = () => {
    const access = Auth.canAccessRolesAndLeader("rolesAccessCRMCreate");

    const accessOperation = Auth.canAccessRolesAndLeader(
      "rolesAccessCRMOperation",
      true
    );

    if (access || accessOperation) {
      return true;
    }

    return false;
  };

  const messageTimezone = process.env.REACT_APP_MESSAGE_TIMEZONE;

  const renderReasonCancel = (customerAppointment) => {
    const notification = getCancelAppointmetCalendly(customerAppointment);

    const { payload } = notification;

    return (
      <>
        <tr>
          <td>Canceled by</td>
          <td>{payload?.cancellation?.canceled_by}</td>
        </tr>
        <tr>
          <td>Canceled on</td>
          <td>{dateFormatCustom(payload?.cancellation?.created_at)}</td>
        </tr>
        <tr>
          <td>Reason cancellation</td>
          <td>{payload?.cancellation?.reason}</td>
        </tr>
      </>
    );
  };

  const buildAppointments = () => {
    return customer.customerAppointments.map((customerAppointment) => {
      return (
        <Accordion key={customerAppointment.id}>
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              {accesEdit() && (
                <Link
                  to="#"
                  className="btn btn-novita-pink-outline"
                  onClick={(event) =>
                    handleEditAppointment(customerAppointment, event)
                  }
                >
                  <FontAwesomeIcon icon={faPencilAlt} />
                </Link>
              )}
              &nbsp;
              {dateFormatCustom(
                customerAppointment.appointmentDate,
                "DD MMM, YYYY HH:mm"
              )}
              &nbsp;<span className="text-danger">({messageTimezone})</span>
              {customerAppointment.lookingFor ? " - " : ""}
              {customerAppointment.lookingFor}
              {customerAppointment.status ? " - " : ""}
              {customerAppointment.status}
            </Accordion.Header>
            <Accordion.Body>
              <Table striped bordered>
                <tbody>
                  <tr>
                    <td>Booking Method</td>
                    <td>{customerAppointment.bookingRequestMethod}</td>
                  </tr>
                  <tr>
                    <td>Looking For</td>
                    <td>{customerAppointment.lookingFor}</td>
                  </tr>
                  <tr>
                    <td>Showroom City</td>
                    <td>{customerAppointment.showroomCity}</td>
                  </tr>
                  <tr>
                    <td>Who Came</td>
                    <td>{customerAppointment.whoCame}</td>
                  </tr>
                  <tr>
                    <td>Is Zoom</td>
                    <td>{customerAppointment.isZoom.toString()}</td>
                  </tr>
                  <tr>
                    <td>Status</td>
                    <td>{customerAppointment.status}</td>
                  </tr>
                  {existCancelAppointmetCalendly(customerAppointment) && (
                    <>{renderReasonCancel(customerAppointment)}</>
                  )}
                  <tr>
                    <td>Type</td>
                    <td>{customerAppointment.type}</td>
                  </tr>
                  <tr>
                    <td>Notes</td>
                    <td>{customerAppointment.notes}</td>
                  </tr>
                  <tr>
                    <td>Appointment Number</td>
                    <td>{customerAppointment.appointmentNumber}</td>
                  </tr>
                  <tr>
                    <td>Sales Representatives</td>
                    <td>
                      <CustomerSalesRepresentatives
                        customerAppointment={customerAppointment}
                      />
                    </td>
                  </tr>
                  {accessFollowUp && (
                    <tr>
                      <td colSpan={2}>
                        <Button
                          className="btn-novita-pink"
                          variant="primary"
                          onClick={(event) =>
                            handleAddFollowup(customerAppointment, event)
                          }
                        >
                          <FontAwesomeIcon
                            icon={faPlus}
                            style={{ cursor: "pointer", marginLeft: "10px" }}
                          />
                          Add Follow Up
                        </Button>
                      </td>
                    </tr>
                  )}
                  {customerAppointment.customerFollowUps.length > 0 && (
                    <tr className="SaleComment_component">
                      <td colSpan={2}>
                        <h3>Follow Ups</h3>
                        <ListGroup>
                          {customerAppointment.customerFollowUps &&
                            customerAppointment.customerFollowUps.length > 0 &&
                            customerAppointment.customerFollowUps.map(
                              (followUp) => {
                                return (
                                  <ListGroup.Item key={followUp.id}>
                                    <Row>
                                      <Col lg="12">
                                        {accessFollowUp && (
                                          <Link
                                            to="#"
                                            className="btn btn-novita-pink-outline"
                                            onClick={(event) =>
                                              handleEditFollowUp(
                                                followUp,
                                                customerAppointment,
                                                event
                                              )
                                            }
                                          >
                                            <FontAwesomeIcon
                                              icon={faPencilAlt}
                                            />
                                          </Link>
                                        )}
                                        &nbsp;
                                        {dateFormatCustom(
                                          followUp.date,
                                          "DD MMM, YYYY"
                                        )}
                                        {followUp.employee?.name
                                          ? " - " + followUp.employee?.name
                                          : ""}
                                      </Col>
                                      <Col lg="12">
                                        <Card className="mt-2">
                                          <Card.Header>Notes:</Card.Header>
                                          <Card.Body>
                                            {followUp.notes}
                                          </Card.Body>
                                        </Card>
                                      </Col>
                                    </Row>
                                  </ListGroup.Item>
                                );
                              }
                            )}
                        </ListGroup>
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      );
    });
  };

  return (
    <div className="general_information customer-details-card">
      <h2
        style={{ height: "42px" }}
        className="d-flex flex-row justify-content-between align-items-center"
      >
        <p className="align-self-center mt-3">Appointments</p>
        {accesEdit() && (
          <Button
            variant=""
            className="btn btn-default"
            onClick={() => {
              setSelectedAppointment(null);
              setshowAddAppointment(true);
            }}
          >
            <FontAwesomeIcon icon={faPlus} />
          </Button>
        )}
      </h2>
      <div className="customer-details-container p-2">
        {buildAppointments()}
      </div>
      {showAddAppointment && (
        <AddAppointmentsForm
          show={showAddAppointment}
          onHide={() => {
            setSelectedAppointment(null);
            setshowAddAppointment(false);
          }}
          customer={customer}
          editingAppointment={selectedAppointment}
        />
      )}

      {showAddFollowup && (
        <AddFollowUps
          show={showAddFollowup}
          onHide={() => {
            setshowAddFollowup(false);
          }}
          customer={customer}
          appointment={selectedAppointment}
          editingFollowup={selectedFollowup}
        />
      )}
    </div>
  );
}
