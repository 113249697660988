import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Col, Form, Row } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faDownload
} from '@fortawesome/free-solid-svg-icons';

const ExportReport = props => {
    const { options, onResponse } = props

    const [report, setReport] = useState(null)

    const changeReport = (event) => {
        const { value } = event.target

        setReport(value)
    }

    const sendDownload = () => {
        onResponse(report)
    }

    return (
        <Col sm="12" lg="12" className='mt-2'>
            <Row>
                <Col sm="9" lg="9">
                    <Form.Group>
                        <Form.Select
                            value={report ?? ''}
                            onChange={(e) => changeReport(e)}
                        >
                            <option value=""></option>
                            {
                                options.map(option => {
                                    return (
                                        <option
                                            key={option}
                                            value={option}>
                                            {option}
                                        </option>
                                    )
                                })
                            }
                        </Form.Select>
                    </Form.Group>
                </Col>

                <Col sm="3" lg="3" className='center-element'>
                    <Button
                        className='btn btn-default not-margin'
                        onClick={sendDownload}
                        disabled={report === null}
                    >
                        <FontAwesomeIcon icon={faDownload} />
                    </Button>
                </Col>
            </Row>
        </Col>
    )
}

ExportReport.propTypes = {
    options: PropTypes.array.isRequired,
    onResponse: PropTypes.func.isRequired
}

export default ExportReport