import Auth from "../../../Auth";
import axiosServices from "../../../axios";
import { REACT_APP_NOVITAERP_API_SALES_MULTIMEDIA_UPDATE_FILE } from "../../../global/const";

export const MultimediaUploadFile = async (file, folder) => {
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${Auth.getToken()}`,
    },
  };

  const params = new FormData();
  params.append("file", file);
  params.append("folder", folder);

  const result = await axiosServices.post(
    `${REACT_APP_NOVITAERP_API_SALES_MULTIMEDIA_UPDATE_FILE}`,
    params,
    config
  );

  const { data = {} } = result;
  const { value, code } = data;

  if (code === 200 && value) {
    return value;
  }

  return null;
};
