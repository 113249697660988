import React from "react";
import { Alert } from "react-bootstrap";

const AccessNotFound = () => {
  return <Alert variant="warning">Access not found</Alert>;
};

AccessNotFound.propTypes = {};

export default AccessNotFound;
