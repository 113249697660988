import { EVENT_TYPE } from "../../../../global/const";

export const existCancelAppointmetCalendly = (customerAppointment) => {
  const { calendlyWebhookNotifications = [] } = customerAppointment;

  if (calendlyWebhookNotifications.length <= 0) {
    return false;
  }

  const notification = getCancelAppointmetCalendly(customerAppointment);

  if (notification) {
    return true;
  }

  return false;
};

export const getCancelAppointmetCalendly = (customerAppointment) => {
  const { calendlyWebhookNotifications = [] } = customerAppointment;

  if (calendlyWebhookNotifications.length <= 0) {
    return null;
  }

  const notifications = calendlyWebhookNotifications.filter(
    (calendlyWebhookNotification) => {
      const notification = JSON.parse(calendlyWebhookNotification.notification);

      return notification.event === EVENT_TYPE ?? notification;
    }
  );

  if (notifications.length > 0) {
    return JSON.parse(notifications[0].notification);
  }

  return null;
};
