import React, { Component } from 'react';
import Auth from './Auth';
import { Link } from 'react-router-dom';


class Notifications extends Component {

    constructor() {
        super();
        this.state = {
            notifications_number: undefined
        }
        this.createWebsocketConnection()
    }

    createWebsocketConnection = () => {
        let socket = new WebSocket(process.env.REACT_APP_NOVITAERP_WS_DOMAIN)
        socket.addEventListener('open', (event) => { })
        socket.addEventListener('message', (event) => {
            const data = JSON.parse(event.data)

            const server_reported_action = data.action
            switch (server_reported_action) {
                case 'report_unique_base_id':
                    //The server is reporting a random id assigned to this ws client.
                    //The client answers back sending the customer id
                    const unique_base_id = data.unique_base_id
                    const user = Auth.getAuthenticatedUser()
                    const ws_report_user_id = {
                        action: 'report_user_id',
                        unique_base_id: unique_base_id,
                        user_id: user.id
                    }
                    socket.send(JSON.stringify(ws_report_user_id))
                    break
                case 'report_amount_notifications':
                    this.setState(
                        {
                            notifications_number: data.amount_notifications
                        }
                    )

                    if(this.props.parentNotificationsNumber){
                        this.props.parentNotificationsNumber(this.state.notifications_number)
                    }

                    break;
                case 'print_message':
                    console.log('The server sent a message: ' + data.message)
                    break;
            }
        })
    }

    render() {
        return (
            <div id="WebSocket_component">
                <Link to="/dashboard">
                    Pending tasks:
                    <span className='number_notifications'>
                        {
                            this.state.notifications_number != undefined ?
                                this.state.notifications_number :
                                null
                        }
                    </span>
                </Link>
            </div>
        )
    }

    componentDidMount() {
    }
}

export default Notifications;