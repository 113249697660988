import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Col, Form, FormLabel, Row } from "react-bootstrap";

const FormSelectCustomer = ({
  label,
  nameInput,
  items,
  keyValue,
  value,
  onResponse,
  disabled,
  required,
  columnsSize,
  showOptionEmpty,
}) => {
  const selected = items.filter((item) => {
    return item[keyValue] === value;
  });

  const [valueNew, setValueNew] = useState(null);

  if (!disabled) {
    disabled = false;
  }

  const changeValue = (event) => {
    const { value } = event.target;

    setValueNew(value);

    onResponse(value, nameInput);
  };

  useEffect(() => {
    if (selected.length > 0 && !valueNew) {
      setValueNew(JSON.stringify(selected[0]));
    }
  }, [selected, valueNew]);

  return (
    <Form.Group className="form-group">
      <Row>
        <Col xs={12} lg={columnsSize}>
          <FormLabel>{label}</FormLabel>
        </Col>
        <Col xs={12} lg={columnsSize}>
          <Form.Select
            required={required}
            onChange={changeValue}
            value={valueNew ?? ""}
            disabled={disabled}
          >
            {showOptionEmpty && <option value="">Select value</option>}
            {items.map((item) => {
              return (
                <option key={item[keyValue]} value={JSON.stringify(item)}>
                  {item[nameInput]}
                </option>
              );
            })}
          </Form.Select>
          <Form.Control.Feedback type="invalid">
            {label.replace("*", "")} is required
          </Form.Control.Feedback>
        </Col>
      </Row>
    </Form.Group>
  );
};

FormSelectCustomer.propTypes = {
  label: PropTypes.string.isRequired,
  nameInput: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  onResponse: PropTypes.func,
  disabled: PropTypes.bool,
  items: PropTypes.array.isRequired,
  keyValue: PropTypes.string.isRequired,
  required: PropTypes.bool,
  showOptionEmpty: PropTypes.bool,
};

FormSelectCustomer.defaultProps = {
  disabled: false,
  columnsSize: 6,
  required: false,
  showOptionEmpty: true,
};

export default FormSelectCustomer;
