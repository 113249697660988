// SCSS
import "../../../../css/sales/sale_create.scss";
import React, { Component } from "react";
import { Modal, Button, Form, FormLabel } from "react-bootstrap";
import axios from "axios";
import FlashMessages from "../../../FlashMessages";
import * as globalConst from "../../../../global/const";
import LoadingLayer from "../../../LoadingLayer";
import Auth from "../../../../Auth";
import { MultimediaUploadFile } from "../../../Multimedia/Action/UploadFile";

class SaleMultimediaEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filename: "",
      description: props.media_edit_selected.description,
      edit_media: false,
      update_file: false,
      selectedMedia: props.media_edit_selected,
      fields_no_updated: true,
    };
    this.closeModal = this.closeModal.bind(this);
    this.updateMedia = this.updateMedia.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.validateFieldsAndSave = this.validateFieldsAndSave.bind(this);
    this.handleFileInput = this.handleFileInput.bind(this);
  }

  closeModal() {
    this.props.closeMediaEditModal();
  }

  validateFieldsAndSave(event) {
    event.preventDefault();
    let error_messages = [];
    if (this.state.update_file === true) {
      if (this.state.filename.length === 0) {
        error_messages.push("Attachment Required");
      } else {
        // Allowing file type
        var allowedExtensions = /(\.jpg|\.mp4|\.png|\.jpeg|\.pdf)$/i;
        if (!allowedExtensions.exec(this.state.filename.name)) {
          error_messages.push("Invalid file type");
        }
      }
    }
    if (this.state.description.length < 5) {
      error_messages.push("Description should have at least 5 characters");
    }
    if (this.state.description.length > 255) {
      error_messages.push("Description should have less than 255 characters");
    }
    if (error_messages.length > 0) {
      this.setState({
        error_messages: error_messages,
      });
    } else {
      if (this.state.update_file === true) {
        this.uploadFile();
      } else {
        this.updateMedia(this.state.filename);
      }
    }
  }

  axioRequest = null;
  updateMedia(filename) {
    this.setState({
      edit_media: true,
      error_messages: [],
    });
    const user = Auth.getAuthenticatedUser();
    if (
      user === null ||
      user === undefined ||
      user.employeeModel === null ||
      user.employeeModel === undefined
    ) {
      this.setState({
        error_messages: [
          "Authentication error. Reload the page and try again.",
        ],
      });
    } else {
      let url =
        process.env.REACT_APP_NOVITAERP_API_DOMAIN +
        globalConst.REACT_APP_NOVITAERP_API_POST_SALE_SERVICE_MULTIMEDIA_UPDATE;
      url = url.replace("{id}", this.state.selectedMedia.id);
      const params = new URLSearchParams();

      if (this.state.update_file === true) {
        params.append("filename", filename);
      }
      params.append("description", this.state.description);

      const config = {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${Auth.getToken()}`,
        },
      };

      axios
        .put(url, params, config)
        .then((response) => {
          const data = response.data;
          if (data !== undefined && data.code === 200) {
            this.setState({
              edit_media: false,
              success_messages: ["Media updated"],
              error_messages: [],
            });
            var edited_sale_media = data.result;
            this.generateEditionLog(edited_sale_media);
            document.getElementById("uploadFile").value = null;
            this.props.updateSaleMultimediaEditRequirementsList();
          } else {
            this.setState({
              edit_media: false,
              error_messages: [
                "There was a problem processing the information. Please reload this page and try again.",
              ],
            });
          }
        })
        .catch((err) => {
          if (err.response) {
            let error_messages = [];
            if ("status" in err.response && err.response.status === 401) {
              error_messages = ["Not authorised."];
            } else {
              error_messages = [
                "There was a problem processing the information. Please reload this page and try again.",
              ];
            }
            this.setState({
              edit_media: false,
              error_messages: error_messages,
            });
          } else if (err.request) {
            const error_messages = [
              "There was a problem processing the information. Please reload this page and try again.",
            ];
            this.setState({
              edit_media: false,
              error_messages: error_messages,
            });
          } else {
            //error not detected. Request could have been cancelled and it's waiting for second
            //request to finish
            this.setState({
              edit_media: true,
            });
          }
          //console.log((err);
          return null;
        });
    }
  }

  uploadFile = async () => {
    this.setState({
      creating_media: true,
      error_messages: [],
    });
    const file = await MultimediaUploadFile(
      this.state.filename,
      globalConst.FOLDER_POST_SALE_SERVICE
    );

    if (file) {
      const { filename } = file;
      this.updateMedia(filename);
    }

    this.setState({
      creating_media: false,
    });
  };

  //Generate log after editing element
  last_sale_media_reported_for_log = null;
  generateEditionLog(edited_sale_media) {
    //AJAX CONTROLS
    //cancel previous ajax request if exists
    if (this.axioRequest !== null) {
      this.axioRequest.cancel();
    }
    // creates a new token for upcomming ajax (overwrite the previous one)
    this.axioRequest = axios.CancelToken.source();

    let sale_media_before_update = this.last_sale_media_reported_for_log;
    if (sale_media_before_update === null) {
      sale_media_before_update = this.props.media_edit_selected;
    }

    delete sale_media_before_update["salesModel"];
    delete edited_sale_media["salesModel"];

    const url =
      process.env.REACT_APP_NOVITAERP_API_DOMAIN +
      globalConst.REACT_APP_NOVITAERP_API_POST_SALE_LOG_NEW;
    const params = new URLSearchParams();

    const user = Auth.getAuthenticatedUser();

    params.append(
      "postSaleServiceId",
      sale_media_before_update.postSaleServiceId
    );
    params.append("employeeId", user.employeeModel.id);
    params.append("oldInformation", JSON.stringify(sale_media_before_update));
    params.append("newInformation", JSON.stringify(edited_sale_media));
    params.append("action", "EDIT_MEDIA");

    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${Auth.getToken()}`,
      },
    };
    axios
      .post(url, params, config)
      .then((response) => {
        const data = response.data;
        if (data !== undefined && data.code === 200) {
        } else {
          console.log("Error storing post_sales_multimedia_log");
        }
        this.axioRequest = null;
      })
      .catch((err) => {
        console.log("Error storing post_sales_multimedia_log.");
        if (err.response) {
          if ("status" in err.response && err.response.status === 401) {
            console.log("Not authorised.");
          } else {
            console.log(
              "There was a problem processing the information. Please reload this page and try again."
            );
          }
        } else if (err.request) {
          console.log(
            "There was a problem processing the information. Please reload this page and try again."
          );
        } else {
          //error not detected. Request could have been cancelled and it's waiting for second
          //request to finish
        }
        //console.log((err);
        return null;
      });

    Object.keys(sale_media_before_update).forEach((key) => {
      if (sale_media_before_update[key] !== edited_sale_media[key]) {
        if (key !== "updated") {
          let url =
            process.env.REACT_APP_NOVITAERP_API_DOMAIN +
            globalConst.REACT_APP_NOVITAERP_API_POST_SALE_EDITION_REQUIREMENT_CREATE;
          const params = new URLSearchParams();

          var rightNow = new Date();
          var date = rightNow.toISOString().slice(0, 10).replace(/-/g, "/");

          params.append(
            "postSaleServiceId",
            sale_media_before_update.postSaleServiceId
          );
          params.append(
            "verificatorRoles",
            "ROLE_ADMIN,ROLE_OPERATIONS_LEADER,ROLE_ACCOUNTING_LEADER,ROLE_ACCOUNTING,ROLE_OPERATIONS,ROLE_SALES,ROLE_CONTENT"
          );
          params.append("fieldName", key);
          params.append("oldValue", sale_media_before_update[key]);
          params.append("newValue", edited_sale_media[key]);
          params.append("employeeId", user.employeeModel.id);
          params.append(
            "created",
            date + " " + new Date().toLocaleTimeString()
          );
          //params.append('updated', '')

          //If oldValue equals newValue
          if (sale_media_before_update[key] === edited_sale_media[key]) {
            return;
          }

          const config = {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
              Authorization: `Bearer ${Auth.getToken()}`,
            },
          };

          axios
            .post(url, params, config)
            .then((response) => {
              const data = response.data;
              if (data !== undefined && data.code === 200) {
              } else {
                console.log(
                  "There was a problem processing the information. Please reload this page and try again."
                );
              }
            })
            .catch((err) => {
              if (err.response) {
                let error_messages = [];
                if ("status" in err.response && err.response.status === 401) {
                  error_messages = ["Not authorised."];
                } else {
                  error_messages = [
                    "There was a problem processing the information. Please reload this page and try again.",
                  ];
                }
                console.log(error_messages);
              } else if (err.request) {
                const error_messages = [
                  "There was a problem processing the information. Please reload this page and try again.",
                ];
                console.log(error_messages);
              }

              return null;
            });
        }
      }
    });
  }

  //*****************************************
  //RENDER
  //*****************************************
  render() {
    return (
      <div id="SaleEdit_component">
        <Modal show={true} onHide={this.closeModal}>
          <Modal.Header closeButton>EDIT MEDIA</Modal.Header>
          <Modal.Body>
            {this.state.edit_media === true ? <LoadingLayer /> : ""}
            {
              <FlashMessages
                error_messages={this.state.error_messages}
                warning_messages={this.state.warning_messages}
                success_messages={this.state.success_messages}
              />
            }
            <Form>
              <div className="row">
                <div className="col-12">
                  <Form.Group className="form-group">
                    <FormLabel>New File</FormLabel>
                    <Form.Control
                      required={true}
                      type="file"
                      name="filename"
                      id="uploadFile"
                      accept="image/png, image/jpeg, video/mp4, application/pdf"
                      onChange={this.handleFileInput}
                    />
                  </Form.Group>
                </div>
                <div className="col-12">
                  <Form.Group className="form-group">
                    <FormLabel>Description</FormLabel>
                    <Form.Control
                      required={true}
                      type="text"
                      name="description"
                      value={
                        this.state.description === null
                          ? ""
                          : this.state.description
                      }
                      onChange={this.handleChange}
                    />
                  </Form.Group>
                </div>
              </div>
              <Button
                className="btn-default save-information-btn"
                type="submit"
                onClick={this.validateFieldsAndSave}
                disabled={this.state.edit_media || this.state.fields_no_updated}
              >
                Update Media
              </Button>
            </Form>
          </Modal.Body>
        </Modal>
      </div>
    );
  }

  handleChange(event) {
    const field_name = event.target.name;
    let state = {};
    state[field_name] = event.target.value;
    if (event.target.value !== this.state[field_name]) {
      state["fields_no_updated"] = false;
    }
    this.setState(state);
  }

  handleFileInput(event) {
    const field_name = event.target.name;
    let state = {};
    if (event.target.files.length > 0) {
      state[field_name] = event.target.files[0];
    } else {
      state[field_name] = "";
    }
    state["update_file"] = true;
    if (event.target.value !== this.state[field_name]) {
      state["fields_no_updated"] = false;
    }
    this.setState(state);
  }

  //********************************
  //REWRITING LIFECYCLE METHODS
  //********************************

  static getDerivedStateFromProps(props, state) {
    //called right before rendering the first time or before shouldComponentUpdate in case it was already rendered
    //return values for the state
    return {
      selectedMedia: props.media_edit_selected,
    };
  }

  //********************************
  //REWRITING LIFECYCLE METHODS END
  //********************************
}
export default SaleMultimediaEdit;
