import * as saleEditPermissions from "./global/sale_edit_permissions";

const Auth = {
  isAuthenticated: false,
  authenticate(authentication_result) {
    this.isAuthenticated = true;
    localStorage.setItem("REACT_TOKEN_AUTH", authentication_result.token);
    const user = authentication_result.user;
    delete user.password;
    delete user.created;
    delete user.updated;
    delete user.active;
    if (user.employeeModel.created !== undefined) {
      delete user.employeeModel.created;
    }
    if (user.employeeModel.updated !== undefined) {
      delete user.employeeModel.updated;
    }
    localStorage.setItem(
      "AUTHENTICATED_USER",
      JSON.stringify(authentication_result.user)
    );
  },
  signout() {
    this.isAuthenticated = false;
    localStorage.removeItem("AUTHENTICATED_USER");
    localStorage.removeItem("REACT_TOKEN_AUTH");
  },
  getIsAuth() {
    const token = localStorage.getItem("REACT_TOKEN_AUTH");
    if (token !== null && token !== undefined) {
      const is_token_expired = this.isTokenExpired(token);
      if (is_token_expired === true) {
        this.signout();
      } else {
        this.isAuthenticated = true;
      }
    }
    return this.isAuthenticated;
  },
  getToken() {
    return localStorage.getItem("REACT_TOKEN_AUTH");
  },
  getExpirationDate(token) {
    if (!token) {
      return null;
    }
    const jwt = JSON.parse(atob(token.split(".")[1]));
    // multiply by 1000 to convert seconds into milliseconds
    const expiration_date = (jwt && jwt.exp && jwt.exp * 1000) || null;
    return expiration_date;
  },
  isTokenExpired(token) {
    const expiration_date = this.getExpirationDate(token);
    if (expiration_date === null || expiration_date === undefined) {
      return true;
    } else {
      return Date.now() > expiration_date;
    }
  },
  getAuthenticatedUser() {
    const user_json = localStorage.getItem("AUTHENTICATED_USER");
    return JSON.parse(user_json);
  },
  isAuthorised(section) {
    var isAuthorised = false;
    const access_control = {
      dashboard_modules_crm: [
        "ROLE_ADMIN",
        "ROLE_OPERATIONS_LEADER",
        "ROLE_OPERATIONS",
        "ROLE_ACCOUNTING_LEADER",
        "ROLE_ACCOUNTING",
        "ROLE_SALES_LEADER",
        "ROLE_SALES",
        "ROLE_COSTS_LEADER",
        "ROLE_COSTS",
      ],
      dashboard_modules_all_sales: [
        "ROLE_ADMIN",
        "ROLE_OPERATIONS_LEADER",
        "ROLE_OPERATIONS",
        "ROLE_ACCOUNTING_LEADER",
        "ROLE_ACCOUNTING",
        "ROLE_SALES",
        "ROLE_CONTENT",
        "ROLE_MARKETING",
        "ROLE_COSTS_LEADER",
        "ROLE_COSTS",
      ],
      dashboard_modules_costs: [
        "ROLE_ADMIN",
        "ROLE_ACCOUNTING_LEADER",
        "ROLE_ACCOUNTING",
        "ROLE_COSTS_LEADER",
        "ROLE_COSTS",
      ],
      dashboard_modules_post_sales: [
        "ROLE_ADMIN",
        "ROLE_OPERATIONS_LEADER",
        "ROLE_OPERATIONS",
        "ROLE_ACCOUNTING_LEADER",
        "ROLE_ACCOUNTING",
        "ROLE_SALES",
        "ROLE_COSTS_LEADER",
        "ROLE_COSTS",
      ],
      dashboard_modules_accounting: [
        "ROLE_ADMIN",
        "ROLE_ACCOUNTING_LEADER",
        "ROLE_ACCOUNTING",
      ],
      dashboard_activity_list: [
        "ROLE_ADMIN",
        "ROLE_OPERATIONS_LEADER",
        "ROLE_OPERATIONS",
        "ROLE_ACCOUNTING_LEADER",
        "ROLE_ACCOUNTING",
        "ROLE_SALES",
        "ROLE_CONTENT",
        "ROLE_COSTS_LEADER",
        "ROLE_COSTS",
      ],
      dashboard_activity_complete: [
        "ROLE_ADMIN",
        "ROLE_OPERATIONS_LEADER",
        "ROLE_ACCOUNTING_LEADER",
        "ROLE_ACCOUNTING",
        "ROLE_COSTS_LEADER",
        "ROLE_COSTS",
      ],
      dashboard_activity_assign_task: [
        "ROLE_ADMIN",
        "ROLE_OPERATIONS_LEADER",
        "ROLE_ACCOUNTING_LEADER",
        "ROLE_COSTS_LEADER",
        "ROLE_COSTS",
      ],
      dashboard_activity_assign_own_task: [
        "ROLE_ADMIN",
        "ROLE_OPERATIONS_LEADER",
        "ROLE_OPERATIONS",
        "ROLE_ACCOUNTING_LEADER",
        "ROLE_ACCOUNTING",
        "ROLE_SALES",
        "ROLE_CONTENT",
        "ROLE_COSTS_LEADER",
        "ROLE_COSTS",
      ],
      dashboard_activity_complete_uncomplete: [
        "ROLE_ADMIN",
        "ROLE_OPERATIONS_LEADER",
        "ROLE_ACCOUNTING_LEADER",
        "ROLE_ACCOUNTING",
        "ROLE_COSTS_LEADER",
        "ROLE_COSTS",
      ],
      dashboard_my_tasks_list: [
        "ROLE_ADMIN",
        "ROLE_OPERATIONS_LEADER",
        "ROLE_OPERATIONS",
        "ROLE_ACCOUNTING_LEADER",
        "ROLE_ACCOUNTING",
        "ROLE_SALES",
        "ROLE_CONTENT",
        "ROLE_COSTS_LEADER",
        "ROLE_COSTS",
      ],
      sales_edit_general: [
        "ROLE_ADMIN",
        "ROLE_OPERATIONS_LEADER",
        "ROLE_OPERATIONS",
        "ROLE_SALES",
      ],
      sales_edit_product: [
        "ROLE_ADMIN",
        "ROLE_OPERATIONS_LEADER",
        "ROLE_OPERATIONS",
        "ROLE_SALES",
      ],
      sales_edit_production: [
        "ROLE_ADMIN",
        "ROLE_OPERATIONS_LEADER",
        "ROLE_OPERATIONS",
        "ROLE_ACCOUNTING_LEADER",
        "ROLE_SALES",
        "ROLE_COSTS_LEADER",
        "ROLE_COSTS",
      ],
      sales_edit_accounts: [
        "ROLE_ADMIN",
        "ROLE_OPERATIONS_LEADER",
        "ROLE_OPERATIONS",
        "ROLE_ACCOUNTING_LEADER",
        "ROLE_ACCOUNTING",
        "ROLE_SALES",
      ],
      sales_edit_costs: [
        "ROLE_ADMIN",
        "ROLE_OPERATIONS_LEADER",
        "ROLE_OPERATIONS",
        "ROLE_ACCOUNTING_LEADER",
        "ROLE_ACCOUNTING",
      ],
      sales_edit_sales_and_marketing: [
        "ROLE_ADMIN",
        "ROLE_OPERATIONS_LEADER",
        "ROLE_OPERATIONS",
        "ROLE_SALES",
        "ROLE_MARKETING",
      ],
      sales_comments_list: [
        "ROLE_ADMIN",
        "ROLE_OPERATIONS_LEADER",
        "ROLE_OPERATIONS",
        "ROLE_ACCOUNTING_LEADER",
        "ROLE_ACCOUNTING",
        "ROLE_SALES",
        "ROLE_CONTENT",
        "ROLE_COSTS_LEADER",
        "ROLE_COSTS",
      ],
      sales_comments_create: [
        "ROLE_ADMIN",
        "ROLE_OPERATIONS_LEADER",
        "ROLE_OPERATIONS",
        "ROLE_ACCOUNTING_LEADER",
        "ROLE_ACCOUNTING",
        "ROLE_SALES",
        "ROLE_CONTENT",
        "ROLE_COSTS_LEADER",
        "ROLE_COSTS",
      ],
      sales_create: [
        "ROLE_ADMIN",
        "ROLE_OPERATIONS_LEADER",
        "ROLE_ACCOUNTING_LEADER",
        "ROLE_SALES",
        "ROLE_COSTS_LEADER",
        "ROLE_COSTS",
      ],
      sales_accounting_columns: ["ROLE_ACCOUNTING_LEADER", "ROLE_ACCOUNTING"],
      post_sales_edit_request: [
        "ROLE_ADMIN",
        "ROLE_OPERATIONS_LEADER",
        "ROLE_OPERATIONS",
        "ROLE_SALES",
      ],
      post_sales_edit_back_office: [
        "ROLE_ADMIN",
        "ROLE_OPERATIONS_LEADER",
        "ROLE_OPERATIONS",
        "ROLE_SALES",
      ],
      post_sales_edit_accounting: [
        "ROLE_ADMIN",
        "ROLE_OPERATIONS_LEADER",
        "ROLE_OPERATIONS",
        "ROLE_ACCOUNTING_LEADER",
        "ROLE_ACCOUNTING",
        "ROLE_COSTS_LEADER",
        "ROLE_COSTS",
      ],
      post_sales_comments_list: [
        "ROLE_ADMIN",
        "ROLE_OPERATIONS_LEADER",
        "ROLE_OPERATIONS",
        "ROLE_ACCOUNTING_LEADER",
        "ROLE_ACCOUNTING",
        "ROLE_SALES",
        "ROLE_COSTS_LEADER",
        "ROLE_COSTS",
      ],
      post_sales_comments_create: [
        "ROLE_ADMIN",
        "ROLE_OPERATIONS_LEADER",
        "ROLE_OPERATIONS",
        "ROLE_ACCOUNTING_LEADER",
        "ROLE_ACCOUNTING",
        "ROLE_SALES",
        "ROLE_COSTS_LEADER",
        "ROLE_COSTS",
      ],
      post_sales_create: [
        "ROLE_ADMIN",
        "ROLE_OPERATIONS_LEADER",
        "ROLE_OPERATIONS",
        "ROLE_SALES",
      ],
      accounting_edit_production: [
        "ROLE_ADMIN",
        "ROLE_ACCOUNTING",
        "ROLE_ACCOUNTING_LEADER",
      ],
      accounting_edit_accounts: [
        "ROLE_ADMIN",
        "ROLE_ACCOUNTING_LEADER",
        "ROLE_ACCOUNTING",
      ],
      accounting_edit_costs: [
        "ROLE_ADMIN",
        "ROLE_ACCOUNTING_LEADER",
        "ROLE_ACCOUNTING",
      ],
      accounting_edit_commissions: [
        "ROLE_ADMIN",
        "ROLE_ACCOUNTING_LEADER",
        "ROLE_ACCOUNTING",
      ],
      accounting_comments_list: [
        "ROLE_ADMIN",
        "ROLE_ACCOUNTING_LEADER",
        "ROLE_ACCOUNTING",
      ],
      accounting_comments_create: [
        "ROLE_ADMIN",
        "ROLE_ACCOUNTING_LEADER",
        "ROLE_ACCOUNTING",
      ],
      costs_edit_production: ["ROLE_ADMIN", "ROLE_COSTS_LEADER", "ROLE_COSTS"],
      costs_edit_accounts: ["ROLE_ADMIN", "ROLE_COSTS_LEADER", "ROLE_COSTS"],
      costs_edit_costs: ["ROLE_ADMIN", "ROLE_COSTS_LEADER", "ROLE_COSTS"],
      costs_comments_list: [
        "ROLE_ADMIN",
        "ROLE_ACCOUNTING_LEADER",
        "ROLE_ACCOUNTING",
        "ROLE_COSTS_LEADER",
        "ROLE_COSTS",
      ],
      costs_comments_create: ["ROLE_ADMIN", "ROLE_COSTS_LEADER", "ROLE_COSTS"],
      sales_multimedia_create: [
        "ROLE_ADMIN",
        "ROLE_OPERATIONS_LEADER",
        "ROLE_OPERATIONS",
        "ROLE_ACCOUNTING_LEADER",
        "ROLE_SALES",
        "ROLE_CONTENT",
      ],
      sales_multimedia_edit: [
        "ROLE_ADMIN",
        "ROLE_OPERATIONS_LEADER",
        "ROLE_OPERATIONS",
        "ROLE_ACCOUNTING_LEADER",
        "ROLE_SALES",
        "ROLE_CONTENT",
      ],
      sales_multimedia_delete: [
        "ROLE_ADMIN",
        "ROLE_OPERATIONS_LEADER",
        "ROLE_CONTENT",
      ],
      post_sales_multimedia_create: [
        "ROLE_ADMIN",
        "ROLE_OPERATIONS_LEADER",
        "ROLE_OPERATIONS",
        "ROLE_ACCOUNTING_LEADER",
        "ROLE_SALES",
        "ROLE_CONTENT",
      ],
      post_sales_multimedia_edit: [
        "ROLE_ADMIN",
        "ROLE_OPERATIONS_LEADER",
        "ROLE_OPERATIONS",
        "ROLE_ACCOUNTING_LEADER",
        "ROLE_SALES",
        "ROLE_CONTENT",
      ],
      post_sales_multimedia_delete: [
        "ROLE_ADMIN",
        "ROLE_OPERATIONS_LEADER",
        "ROLE_CONTENT",
      ],
      create_document_category: ["ROLE_SUPER_ADMIN", "ROLE_ADMIN"],
      view_document_history: ["ROLE_SUPER_ADMIN", "ROLE_ADMIN"],
      view_results_analysis: ["ROLE_ADMIN", "ROLE_SALES"],
    };

    const user = this.getAuthenticatedUser();
    const rolesWithLeaderAccess = ["OPERATIONS", "ACCOUNTING", "COSTS"];

    access_control[section].forEach(function (role, index) {
      if (role.includes("_LEADER")) {
        var leaderRole = role.replace("_LEADER", "");
        if ("employeeModel" in user) {
          if ("isLeader" in user.employeeModel) {
            if (
              user.employeeModel.isLeader &&
              user.roles.includes('"' + leaderRole + '"')
            ) {
              isAuthorised = true;
            }
          }
        }
      } else {
        if ("employeeModel" in user) {
          if ("isLeader" in user.employeeModel) {
            if (rolesWithLeaderAccess.includes(role)) {
              if (
                !user.employeeModel.isLeader &&
                user.roles.includes('"' + role + '"')
              ) {
                isAuthorised = true;
              }
            } else {
              if (user.roles.includes('"' + role + '"')) {
                isAuthorised = true;
              }
            }
          }
        }
      }
    });
    return isAuthorised;
  },
  canEditField(field_name) {
    var canEdit = false;
    const user = this.getAuthenticatedUser();

    saleEditPermissions[field_name].forEach(function (role, index) {
      if (user.roles.includes('"' + role + '"')) {
        canEdit = true;
      }
    });
    return canEdit;
  },
  canAccessRolesAndLeader(roles, isLeader = false) {
    let user = this.getAuthenticatedUser();

    if (this.canEditField("rolesAllAccess")) {
      return true;
    }

    if (isLeader) {
      if (this.canEditField(roles) && user.employeeModel.isLeader) {
        return true;
      }
    } else {
      if (this.canEditField(roles)) {
        return true;
      }
    }

    return false;
  },
  isLeader() {
    let user = this.getAuthenticatedUser();

    if (user.employeeModel.isLeader) {
      return true;
    }

    return false;
  },
  getEmployee() {
    let user = this.getAuthenticatedUser();

    const { employeeModel = null } = user;

    if (employeeModel) {
      return employeeModel;
    }

    return {};
  },
};
export default Auth;
